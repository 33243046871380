// Importações
import { createWebHistory, createRouter } from "vue-router";
import privateRoutes from './PrivateRoutes.js';
import publicRoutes from './PublicRoutes.js';
import store from '@/store';

// Configurações das Rotas
const routes = [...privateRoutes, ...publicRoutes];

const router = createRouter({
  history: createWebHistory(),
  routes,
});


router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.auth.user; // Check if user is authenticated
  const hasFuncionario = store.getters['funcionario/funcionarioAtual']; // Check if funcionario is set

  if (to.name === 'Login') {
    // If already going to Login, just continue
    next();
  } else if (!isAuthenticated) {
    // If not authenticated, redirect to Login
    next({ name: 'Login' });
  } else if (to.name !== 'admin.dashboard' && !hasFuncionario) {
    // If trying to access a route other than admin.funcionarios without a funcionario, redirect
    // Here you can decide where to redirect, e.g., to a page where they can select a funcionario or show an error
    // For this example, redirecting to the funcionarios selection page (assuming its name is 'admin.funcionarios')
    next({ name: 'admin.dashboard' });
  }

  else {
    // If none of the above conditions met, proceed as normal
    next();
  }
})

export default router;
